// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';
import FORM_OPERATION_BODY from '@/graphql/shared/form-operation-body';

const QUERY = gql`
  query UpdatePasswordForm {
    form: updatePasswordForm {
      ${FORM_OPERATION_BODY}
    }
  }
`;

// old_password, password and password_confirmation
const MUTATION = gql`
  mutation UpdatePassword($input: UpdatePassword!) {
    form: updatePassword(input: $input) {
      status
      message
    }
  }
`;

export { QUERY, MUTATION };
